import React from "react";

const DesignSection = () => {
  return (
    <div
      name="Design"
      className="relative flex flex-col bg-transparent items-center  text-white  lg:w-screen h-full lg:mt-[0rem] mb-[5rem] py-[5rem] lg:h-screen  font-kanit "
    >
      <div>
        <h2 className="text-[24px] lg:text-[56px] leading-[22px] lg:leading-[50px] font-extrabold    text-center ">
          DESIGN DE LA A LA Z <br />
        </h2>
        <h3 className="text-[16px] text-gray-200 text-center lg:text-[26px]">
          Creeaza imaginea brandului tau
        </h3>
      </div>

      <div className="relative   flex flex-col lg:items-start items-center w-[80%] lg:w-[55rem] font-montSerrat">
        <p className="mt-[1rem] text-[16px] lg:text-[24px]">
          Impactul vizual este cel mai important factor in social media, poti
          avea cel mai bun produs sau cel mai bun serviciu, daca prezentarea
          vizuala este de slaba calitate atunci orice utilizator al acestor
          platforme ar trece cu usurinte peste postarea ta
        </p>
        <div className="mt-[5rem]">
          <h3 className="text-[24px] lg:text-[56px] leading-[22px] lg:leading-[50px] font-extrabold    text-center ">
            EDITARE VIDEO (SHORTS)
          </h3>
          <p className="w- mt-[1rem] text-[16px] lg:text-[24px]">
            Atentia este captata din ce in ce mai greu, videoclipurile de tip
            shorts au luat amploare in ultimii ani si o sa fie cu siguranta
            tipul de continut care ajunge la cei mai multi oameni, chiar si
            printr-un mod organic Adaugarea de subtritari si de efecte speciale
            mentin utilizatorul captivat pentru a intelege ideea esentiala a
            continutului video unde se pot plasa cu usurinta produse sau
            servicii
          </p>
        </div>
      </div>
    </div>
  );
};

export default DesignSection;
