import React from 'react'

const TermeniSiConditii = () => {
  return (
    <div className='flex flex-col items-center   bg-white py-[10rem]'>
      <div className='w-[80%] lg:w-[50rem]'>
           <h6 className='font-bold text-[28px]'> TERMENI SI CONDITII</h6>
          Adresa oficiala a website-ului nostru:<a href="https://waygital.ro" className='font-bold'> https://waygital.ro </a>.
        <p className=''>
     
          <span className='font-bold'>1. Conditii generale</span>
        </p>
        <p className='mt-[1rem]'>
          1.1 Acest document reprezintă cadrul legal privind raporturile dintre dumneavoastră și WAYGITAL VISIONARY S.R.L., societate care administrează site-ul www.waygital.ro, purtand in continuare denumirea comerciala “WAYGITAL”
        </p>
        <p className='mt-[.5rem]'>         
           WAYGITAL VISIONARY S.R.L. este o persoană juridică română inființată potrivit legislației române, cu sediul în Buzău, loc. Buzău, oraș Buzău, str. Aleea Crăițelor nr.1 , județ Buzău, având codul unic de înregistrare RO47656533, fiind înregistrat la Registrul Comerțului sub nr. J10/152/2023.
        </p>
        <p className='mt-[.5rem]'> 
          Va rugăm să citiți Termenii și Condițiile („Regulamentul”) pentru utilizarea în orice mod a website-ului accesibil la adresa URL: www.waygital.ro. Acesarea si vizitarea Website-ului, înregistrarea unui cont pe Website și/sau achizitionarea de produse (“Serviciile”) înseamnă acceptarea în totalitate și necondiționată a acestor Termeni și Condiții. Va rugam sa parcurgeti cu atentie Regulamentul pentru a preintampina eventuale consecinte nedorite, ce pot fi apreciate de catre dvs. ca avand un impact negativ asupra datelor si/sau informatiilor furnizate catre WAYGITAL VISIONARY S.R.L.
       </p>
       <p className='mt-[.5rem]'> 
          1.2 Programul de asistenta on-line disponibil pe site-ul <span className='font-bold'> www.waygital.ro </span> este: Luni-Vineri in intervalul 08:00-22:00,Sâmbătă-Duminică în intervalul 08:00-19:00.
       </p>
       <p className='mt-[.5rem]'> 
          1.3 Programul site-ului <span className='font-bold'>www.waygital.ro</span> – Non-stop!
       </p>
       <p className='mt-[.5rem]'> 
          1.4 WAYGITAL își rezervă dreptul de a modifica sau de a întrerupe, temporar sau permanent, parțial sau în totalitate, serviciile puse la dispoziția utilizatorilor prin intermediul site-ului <span className='font-bold'> www.waygital.ro </span> cu sau fără o notificare prealabilă, fara a fi raspunzători față de utilizatori („Clienți”) sau față de orice terță persoană fizică sau juridică ori instituție în acest sens.
        </p>
        <p className='mt-[.3rem]'> 
          1.5 Clienții au acces permanent la termenii și condițiile de utilizare, conform prezentului Regulament, pe care îl pot consulta pe toata durata de funcționare a site-ului<span className='font-bold'> www.waygital.ro </span> . Clientul poate în mod liber și nestingherit naviga pe site, utiliza site-ul, crea cont, plasa comanda.
        </p>
        <h6 className='mt-[1rem]'> 
           2. <span className='font-bold'> Conținutul Site-ului si Condiții de Utilizare </span>
        </h6>
        <p className='mt-[.5rem]'> 
          2.1 Accesul se face prin accesarea adresei URL: <span className='font-bold'> www.waygital.ro. </span>
        </p>
        <p className='mt-[.5rem]'> 
          2.2 Prin folosirea site-ului <span className='font-bold'>  www.waygital.ro  </span>, Clientul este singurul responsabil pentru toate activitățile ce decurg din folosirea acestuia. De asemenea, acesta este responsabil pentru orice daune materiale, intelectuale ori electronice sau de orice altă natura produse site-ului, in conformitate cu legislatia română in vigoare.
        </p>
        <p className='mt-[.5rem]'> 
          2.3 Prin folosirea site-ului nostru, Clientul acceptă in mod expres și fără echivoc prezentul Regulament, în ultima versiune actualizată, care este comunicată in secțiunea “Termeni si Condiții” a site-ului, existentă la data creării contului și/sau utilizarii continutului și/sau la data plasării comenzii. WAYGITAL VISIONARY își rezervă dreptul de a modifica prezentii Termeni si Condiții, urmând ca versiunile actualizate să fie publicate pe website-ul WAYGITAL.
        </p>
        <p className='mt-[.5rem]'> 
          2.4 Folosirea site-ului <span className='font-bold'> www.waygital.ro </span> și serviciile oferite de acesta sunt destinate exclusiv persoanelor care au împlinit vârsta de 18 ani și persoanelor juridice inmatriculate care funcționează în acord cu legile din România, ale căror reprezentanți sunt autorizați să exprime acordul legal cu privire la accesarea, vizitarea sau utilizarea în orice alt mod a Website-ul și/sau a Serviciilor.
        </p>
        <p className='mt-[.5rem]'> 
          2.5 Prin inregistrarea unei comenzi pe site, Clientul este de acord cu forma de comunicare (telefonic sau e-mail) prin care www.waygital.ro isi deruleaza operatiunile comerciale. 
        </p>
        <p className='mt-[.5rem]'> 
          2.6 Clientul poate efectua comenzi pe site-ul <span className='font-bold'> www.waygital.ro </span>, prin selectarea produselor dorite, urmând a finaliza comanda efectuând plata prin una dintre modalitățile indicate expres. Selectarea unui produs în lipsa finalizării comenzii, nu atrage după sine înregistrarea unei comenzi, implicit nici rezervarea automata a produsului respectiv. 
        </p>
        <p className='mt-[.5rem]'> 
          2.7 Prin finalizarea comenzii, Clientul își asumă că toate datele furnizate de acesta, necesare etapelor de achiziție, sunt corecte, complete si adevărate la data plasării comenzii. Prin data incheierii contractului se înțelege momentul la care contul KEMO VENTURE a fost debitat cu valoarea prețului aferent serviciilor achizitionate si oferta de a contracta a ajuns la cunostința ofertantului, cu respectarea prevederilor Legii 365/2002 privind comerțul electronic. WAYGITAL VISIONARY va pastra un extras si o evidenta a contractelor incheiate.  Contractele pot fi incheiate atat in limba romana cat si in limba engleza.
        </p>
        <p className='mt-[.5rem]'> 
          2.8 Totodată, Clientul consimte să fie contactat prin mijloacle disponibile și agreate, în orice situație în care WAYGITAL VISIONARY S.R.L apreciază că este necesară contactarea sa, in conformitate cu Politica de prelucrare a datelor cu caracter personal a WAYGITAL VISIONARY S.R.L
        </p>
        <p className='mt-[.5rem]'> 
          2.9 WAYGITAL VISIONARY S.R.L poate anula comanda efectuată de către Client, în urma unei simple notificări prealabile adresate acestuia, fără nicio obligație ulterioara a vreunei parti față de cealaltă sau fără ca vreo parte să poata pretinde celeilalte daune-interese, in urmatoarele cazuri: (i) neacceptarea de catre bancă emitentă a cardului Clientului, a tranzacției, în cazul plății online; (ii) invalidarea tranzacției de către procesatorul de carduri agreat de www.waygital.ro, in cazul plății online și/sau (iii) datele furnizate de către Client, pe site sunt incomplete și/sau incorecte.
        </p>
        <p className='mt-[.5rem]'> 
          2.10 Prețurile produselor afișate in cadrul site-ului <span className='font-bold'> www.waygital.ro </span> nu includ T.V.A. Prețul, modalitatea de plată și termenul de plată sunt specificate în fiecare comandă. WAYGITAL VISIONARY S.R.L va emite către Client o factură pentru produsele livrate, obligația Clientului fiind să furnizeze toate informațiile necesare emiterii facturii în conformitate cu legislația în vigoare.
        </p>
        <p className='mt-[.5rem]'> 
          2.11 WAYGITAL VISIONARY S.R.L îsi rezervă dreptul de a vă transmite factura aferentă comenzii ce contine produsele vândute pe site-ul www.waygital.ro, exclusiv în format electronic, prin adăugarea facturii în contul dvs. sau prin poștă electronică, la adresa de e-mail mentionață de Client in contul său. In cazul plăților online, WAYGITAL VISIONARY S.R.L nu este/nu poate fi facut responsabil pentru niciun alt cost suplimentar suportat de Client, cum ar fi comisioane de conversie valutară aplicate de catre banca emitentă a cardului acestuia, în cazul in care moneda de emitere diferă de RON. Responsabilitatea pentru aceasta acțiune îi revine în exclusivitate Clientului.
        </p>
        <p className='mt-[.5rem]'> 
          2.12 Proprietatea asupra produselor comercializate pe site-ul <span className='font-bold'> www.waygital.ro </span> aparține in exclusivitate WAYGITAL VISIONARY S.R.L. Fiind un produs SaaS si pentru a proteja know-how-ul, clientul nu primeste user de administrator. Tipul de acces maxim oferit este – editor – shop manager. Pentru diverse funcționalități/module se poate, la cerere, acorda permisiune dedicată pentru acestea.
        </p>
        <p className='mt-[.5rem]'> 
          2.13 Costurile produselor si serviciilor oferite de WAYGITAL VISIONARY S.R.L prin intermediul site-ului <span className='font-bold'> www.waygital.ro </span>, sunt afisate pe pagina de pornire a site-ului și/sau în secțiunea “Prețuri” din meniul principal și se achită la cursul BNR din ziua plății.
        </p>
        <p className='mt-[.5rem]'> 
          2.14 In cazul în care clientul dorește să migreze spre altă solutie, WAYGITAL VISIONARY S.R.L preda produsele, comenzile, clienții, fisierele media si continutul text de pe site-ul care face obiectul comenzii Clientului si, in cazul in care ambele parti agreeaza, ajuta Clientul în procedura de migrare a acestuia (ex: ține legatură cu echipa tehnică care implementează noua soluție și se asigură că acest proces are loc cât mai rapid și optim pentru Client).
        </p>
        <p className='mt-[.5rem]'> 
          2.15 Toate informațiile folosite pentru descrierea produselor disponibile pe site-ul <span className='font-bold'> www.waygital.ro </span> (imagini statice/dinamice/prezentari multimedia/etc.) nu reprezintă o obligație contractuală în sarcina WAYGITAL VISIONARY S.R.L, acestea fiind utilizate exclusiv cu titlu de prezentare
        </p>
        <p className='mt-[.5rem]'> 
          2.16 Conținutul site-ului <span className='font-bold'> www.waygital.ro </span> nu poate fi utilizat, reprodus, distribuit, transmis sau expus în alte scopuri decât cele expres prevazute de legislatia in vigoare aplicabila pe teritoriul României. Extragerea oricăror informații, urmata de orice utilizare în scop comercial, care depășește sfera copiei private reglementate de lege, sau pentru vânzare ori licențiere si fără a avea în prealabil un consimțământ scris al titularilor drepturilor de proprietate, constituie o încalcare a prezentului Regulament.
        </p>
        <p className='mt-[.5rem]'> 
          2.17 Ce primeste clientul la achiziționarea produsului „website de prezentare” în pretul standard?
        </p>
        <p className='mt-[.5rem]'> 
          Website de prezentare de până la 5 pagini de servicii + homepage, toate cele 6 pagini fiind optimizate perfect pentru motoarele de căutare, pentru cuvintele relevante business-ului aprobate de client în urma researchului WAYGITAL, în cazul de față Google;
          Performanță testată cu Google Page Speed Insights, cu rezultat peste 90 pe dispozitivele mobile;
          Paginile standard de contact/blog/termene și condiții/politica de confidențialitate/despre noi;
          Orice pagină extra poate fi facută contra-cost, la cererea clientului, în afara timpului de livrare stabilit initial, iar tariful este stabilit pentru orele de muncă necesare realizării, ori în funcție de pachetele predefinite de servicii adiționale, disponibile pe site-ul <span className='font-bold'> www.waygital.ro </span>;
          Dreptul la modificari: se oferă o SINGURA modificare majoră asupra website-ului, bazata strict pe un motiv extrem de clar. Aceata modificare va fi evaluata exclusiv de catre WAYGITAL VISIONARY S.R.L.;
          Termenul de livrare mediu precizat pe <span className='font-bold'> www.waygital.ro </span> pentru crearea website-ului sau magazinului online curge de la data la care clientul furnizează TOATE INFORMAȚIILE (fișier produse completat corect gata de importat, brief completat corect, media – poze – video primite corect), iar acest termen de livrare se referă la prima versiune gata de revizuit de către client, nu a site-ului în varianta lui finală. Acest termen de livrare este valabil strict în limita ocupației echipei WAYGITAL. Termenul de livrare poate depăși numărul de zile precizat pe site în condițiile în care numărul de proiecte active este crescut.
        </p>
        <p className='mt-[.5rem]'> 
          2.18 Ce primește clientul la achiziționarea produsului „website magazin online” in prețul standard?
        
        <ol>
          <li>
            Website magazin online cu maxim 8 pagini de categorii + homepage, toate cele 9 pagini fiind optimizate perfect pentru motoarele de căutare, pentru cuvintele relevante business-ului aprobate de client în urma researchului WAYGITAL, în cazul de fată Google;
          </li>
          <li>
            Performanța testată cu Google Page Speed Insights, cu rezultat peste 90 pe dispozitivele mobile;
          </li>
          <li>
            Paginile standard de contact/blog/termene si condiîii/politica de confidenîialitate/metode de plată/metode de livrare/despre noi;
          </li>
          <li>
            Orice pagina extra poate fi facută contra-cost, la cererea clientului, in afara timpului de livrare stabilit inițial, iar tariful este stabilit pentru orele de muncă necesare realizarii, ori în funcție de pachetele predefinite de servicii adiționale, disponibile pe site-ul <span className='font-bold'> www.waygital.ro </span>;
          </li>
          <li>
            Dreptul la modificari: se ofera o SINGURA modificare majoră asupra website-ului, bazata strict pe un motiv extrem de clar. Aceată modificare va fi evaluată exclusiv de către WAYGITAL VISIONARY S.R.L. 
          </li>
          </ol>
          </p>
           <h6 className='mt-[1rem] '>3. <span className='font-bold'>Garanții și renuntarea produselor </span></h6>
          <p className='mt-[.5rem]'>
            3.1 Drepturile dvs. sunt prevăzute de OUG 140/2021 privind anumite aspecte referitoare la contractele de vânzare de bunuri și nu sunt afectate în niciun fel de prezentul Regulament. Clientul persoană fizică are dreptul de a denunța unilateral contractul la distanță, în termen de 14 zile calendaristice de la efectuarea plății, fără penalități și făra invocarea vreunui motiv, prin notificarea WAYGITAL VISIONARY S.R.L, in conformitate cu OUG 34/2014 privind drepturile consumatorilor in cadrul contractelor incheiate cu profesioniștii, precum și pentru modificarea și completarea unor acte normative.
          </p>
            3.2. In situatia in care clientul dorește solicitarea rezilierii înainte de livrarea produsului, WAYGITAL VISIONARY S.R.L. își rezervă dreptul de a reține suma achitată in totalitate/parțial de către client, dupa aprecierea exclusivă a WAYGITAL.
          <p className='mt-[.5rem]'> 
            3.3 Returnarea baniilor în 15 zile de la efectuarea plății se face în anumite condiții în care cumpărătorul are un motiv extrem de clar și bine întemeiat. Nu este acceptată sub nicio formă returnarea banilor pentru motive ex: „nu îmi place”, „nu vreau doar atât” și/sau alte motive care nu constituie încălcarea unei promisiuni nelivrate din descrierea produselor comercializate de WAYGITAL.
          </p>
          <h6 className='mt-[1rem]'>4.<span className='font-bold'>Prelucrarea datelor cu caracter personal</span></h6>
          <p className='mt-[.5rem]'> 
            4.1 Va rugăm să accesați secțiunile „Politica de Confidentialitate”, precum și secțiunea „Prelucrarea datelor cu caracter personal” – www.waygital.ro, părți integrante din prezentul Regulament.
          </p>
          <h6 className='mt-[1rem] font-bold'>  Dispoziții finale </h6>
          <p className='mt-[.5rem]'> 
            5.1 În limita prevederilor acestui Regulament, WAYGITAL VISIONARY S.R.L nu va putea fi ținută responsabilă pentru eventualele erori apărute pe site-ul www.waygital.ro din orice cauză, inclusiv din cauza unor modificări, setări, etc., care nu sunt facute de către administratorul site-ului.

            5.2 Administratorul Site-ului <span className='font-bold'> www.waygital.ro </span> își rezervă dreptul de a introduce bannere publicitare de orice natură și/sau legături pe oricare pagină a site-ului web, cu respectarea legislației în vigoare. Toate imaginile de pe site-ul <span className='font-bold'> www.waygital.ro </span> sunt cu titlu de prezentare.

            5.3 Prezentul Regulament va fi interpretat in conformitate cu legislația română și constituie singurul instrument juridic probatoriu pentru soluționarea eventualelor litigii între părți. Eventualele litigii aparute între WAYGITAL VISIONARY S.R.L în calitate de administrator a site-ului <span className='font-bold'> www.waygital.ro </span> si Client se vor rezolva pe cale amiabilă sau, in cazul în care aceasta nu va fi posibilă, litigiile vor fi soluționate de către instanțele judecătorești române competente de la sediul WAYGITAL VISIONARY S.R.L .

            5.4 Niciuna dintre părți nu va fi raspunzătoare pentru neexecutarea obligatiilor sale contractuale, daca o astfel de neexecutare este consecința unui eveniment de forță majoră, în conformitate cu legislația în vigoare.

            5.5 Fiecare comandă acceptată de către administratorul <span className='font-bold'> www.waygital.ro </span>, este guvernată de prezentul Regulament în forma publicată pe site-ul <span className='font-bold'>  www.waygital.ro </span>, la data acceptării comenzii, iar clienții vor verifica de fiecare dată Regulamentul în forma actualizată, urmând să plaseze o noua comandă numai în cazul în care sunt de acord cu acesta.
          </p>
          <h6 className='font-bold mt-[1rem]'>  6. Reclamații și sesizări </h6>
          <p className='mt-[.5rem]'>
            6.1 Pentru sesizari sau reclamații legate de produsele achiziționate, clienții au la dispoziție formularul de sesizări din cadrul site-ului: www.waygital.ro. Termenul maxim de solutionare a reclamațiilor sau sesizărilor este de 30 de zile calendaristice de la primirea acestora.

            6.2 WAYGITAL VISIONARY S.R.L nu poate fi responsabil pentru daune de orice fel pe care clientul sau orice tert le poate suferi ca rezultat al îndeplinirii de către WAYGITAL VISIONARY S.R.L a oricăreia dintre obligațiile sale conform comenzii, nici pentru daune care rezultă din utilizarea necorespunzătoare a produselor după livrare și nici pentru pierderea acestora.

            6.4. Prin crearea si utilizarea contului, Clientul își asumă răspunderea pentru menținerea confidențialitătii datelor de cont (user și parola) și pentru gestionarea accesării contului și, în masura permisă de legislația în vigoare, este responsabil de activitatea derulată prin intermediul contului sau. WAYGITAL VISIONARY prelucrează datele cu caracter personal cu respectarea normelor aplicabile în materie. Pentru informatii suplimentare in legatura cu prelucrarea datelor cu caracter personal, vă rugam să accesați Politica noastră de confidențialitate.

            6.5 Orice incercare de a accesa datele personale ale clienților sau de a modifica conținutul site-ului <span className='font-bold'> www.waygital.ro </span> sau de a afecta performanțele server-ului pe care ruleaza site-ul nostru va fi considerată o tentativă la una dintre infracțiunile prevăzute de Cap. IV, Titlul II din partea specială a Codului penal.
          </p> 
    
    </div>
    </div>
  )
}

export default TermeniSiConditii