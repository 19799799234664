import React from "react";
import Ai from "./Images/robot.png";
const AiPresentation = () => {
  return (
    <div
      name="AI"
      className="relative flex flex-col bg-transparent items-center  text-white  w-screen h-full lg:mt-[0rem] mb-[5rem] py-[5rem] lg:h-screen  font-kanit "
    >
           <h2 className="text-[24px] lg:text-[56px] leading-[22px] lg:leading-[50px] font-extrabold    text-center ">
          AUTOMATIZARI CU AI <br /> ( INTELIGENTA ARTIFICIALA )
        </h2>
      <img className="mt-[3rem] lg:absolute lg:left-[-29rem] xl:left-[-26rem] 2xl:left-[-20rem]  top-0 w-[15rem] lg:w-[35rem] xl:w-[40rem]" src={Ai} />
   
      
        <div className="relative   flex flex-col lg:items-start items-center w-[80%] lg:w-[55rem] font-montSerrat">
   
        <p className="mt-[1rem] text-[16px] lg:text-[24px]">
          De la creearea unui chat bot care poate raspunde prompt la un numar
          mare de clienti in acelasi timp, pana la crearea unei voci
          artificiale, antrenata sa replice vocea unei persoane reale, toate
          aceasta constituie doar o mica parte din totalul de lucruri pe care le
          poti integra in afacerea ta
        </p>
        </div>
     
    </div>
  );
};

export default AiPresentation;
