import React from 'react'

const PoliticaCookie = () => {
  return (
    <div className='flex flex-col items-center   bg-white py-[10rem]'>
    <div className='w-[80%] lg:w-[50rem]'>
              <h6 className='font-bold text-[28px]'>Politica Cookies</h6>
      <h6 className=' mt-[1rem] font-bold'> 1. Politica de utilizare Cookie-uri </h6>
      <p className='mt-[.5rem]'>
        Este important de a fi transparenți și deschiși în legătură cu modul în care colectăm și utilizăm datele care se referă la dvs.
        În spiritul transparenței, această politică furnizează informații detaliate despre cum și când utilizăm fișierele Cookie.
        Această politică se referă la cookie-urile și paginile web operate de <a href="https://waygital.ro" className='font-bold'> waygital.ro </a> .
      </p>
      <h6 className='mt-[1rem] font-bold'>2. Ce sunt Cookie-urile? </h6>
      <p className='mt-[.5rem]'>
        Cookie-ul este un fișier de mici dimensiuni, format din litere și numere, care va fi stocat pe terminalul utilizatorului (de ex. computerul, terminalul mobil sau alte echipamente ale unui utilizator de pe care se accesează internetul).
        Cookie-ul este instalat prin solicitarea emisă de către un web-server unui browser (ex: Internet Explorer, Firefox, Opera, Safari, Chrome etc.) și este complet “pasiv” (nu conține programe software, viruși sau spyware și nu poate accesa informațiile de pe hard driverul utilizatorului).
        Cookie-urile joacă un rol important în facilitarea accesului și livrării multiplelor servicii de care vă bucurați pe Internet, cum ar fi afișarea corectă a unui site atunci când îl vizitați folosind computerul, terminalul mobil sau alte echipamente de pe care accesați Internetul, personalizarea anumitor setări (limba, țara, prețuri afișate în moneda națională, etc.), permiterea accesului în site-ul web, etc.
        Cookie-urile oferă deținătorilor de site-uri un feedback valoros asupra modului în care sunt utilizate site-urile lor de către utilizatori, astfel încât să le poată face mai eficiente și mai accesibile pentru utilizatori și să poată include aplicații sau functionalități în site care creează o experiență de navigare mai simplă, mai utilă și mai placută.
      </p>
     <h6 className= 'mt-[1rem] font-bold'> 3. La ce sunt folosite Cookie-urile? </h6>
     <p className='mt-[.5rem]'>
        Fișierele Cookie fac posibilă recunoașterea terminalului utilizatorului și prezentarea conținutului într-un mod relevant, adaptat preferințelor utilizatorului.
        Cookie-urile asigură utilizatorilor o experiență plăcută de navigare și susțin eforturile <a href="https://waygital.ro" className='font-bold'> waygital.ro </a> pentru a oferi servicii confortabile utilizatorilor , de exemplu: – preferințele în materie de confidențialitate online, servicii sau publicitate relevantă.
        De asemenea, sunt utilizate în pregătirea unor statistici anonime agregate care ne ajută să înțelegem cum un utilizator beneficiază de paginile noastre web, permițându-ne îmbunătățirea structurii și conținutului lor, excluzând identificarea personală a utilizatorului.
      </p>
      <h6 className='mt-[1rem] font-bold'>4. Ce Cookie-uri folosim? </h6>
      <p className='mt-[.5rem]'>
        Folosim două tipuri de Cookie-uri: de sesiune și persistente.
        Cookie-urile de sesiune sunt fișiere temporare care sunt active și rămân în terminalul utilizatorului până la terminarea sesiunii sau închiderea aplicației (browser-ului web).
        Cookie-urile persistente sunt fișierele stocate, în funcție de durata prestabilită, pe suportul de stocare a unui computer sau echipament.
      </p>
      <h6 className='mt-[1rem] font-bold'>5. Ce sunt cookie-urile plasate de terți?</h6>
      <p className='mt-[.5rem] '>
        Anumite secțiuni de conținut de pe pagina de internet pot fi furnizate prin intermediul unor terți, adică nu de către <a href="https://waygital.ro" className='font-bold'>waygital.ro</a> , caz in care aceste cookie-uri sunt denumite cookie-uri plasate de terți (“third party cookie-uri”).

        Terții furnizori ai cookie-urilor trebuie sa respecte, de asemenea, regulile in materie de protecție a datelor si Politica de Confidențialitate.

        Aceste cookie-uri pot proveni de la următorii terți: Google, Facebook, Cloudflare.
      </p>
      <h6 className='mt-[1rem] font-bold'>6. Cum sunt folosite Cookie-urile de către acest site?</h6>
      <p className='mt-[.5rem]'>
        O vizită pe acest site poate plasa Cookie-uri în scopuri de:
        Cookie-uri de performanță a site-ului
        Cookie-uri de analiză a vizitatorilor
        Cookie-uri pentru geotargetting
        Cookie-uri de înregistrare
        Cookie-uri pentru publicitate
        Cookie-uri ale furnizorilor de publicitate
        Aceste Cookie-uri pot fi proprii sau pot proveni de la terți precum Google Analytics, Facebook sau alte platforme/site-uri web care urmăresc activitatea dvs. în scopuri de marketing.
      </p>
      <p className='mt-[.5rem]'>
        Cookie-uri de performanță
        Acest tip de Cookie reține preferințele utilizatorului pe acest site, așa încât nu mai este nevoie de setarea lor la fiecare vizitare a site-ului. Spre exemplu: setările volumului pentru video player, viteza de video streaming cu care este compatibil browser-ul.
        Cookie-uri pentru analiza vizitatorilor
        De fiecare dată când un utilizator vizitează acest site, softul de analytics furnizat de o terță parte generează un Cookie de analiză a utilizatorului. Acest Cookie ne spune dacă ați mai vizitat acest site până acum.
        Browser-ul ne va spune dacă aveți acest Cookie, iar dacă nu, vom genera unul. Acesta permite monitorizarea utilizatorilor unici care ne vizitează și cât de des o fac.
        Atât timp cât nu sunteți înregistrat pe acest site, acest Cookie nu poate fi folosit pentru a identifica persoanele fizice, ele sunt folosite doar în scop statistic.
        Dacă sunteți înregistrat putem ști, de asemenea, detaliile pe care ni le-ați furnizat, cum ar fi adresa de e-mail și username-ul – acestea fiind supuse confidențialității și prevederilor din Termeni si Condiții, Politica de confidențialitate precum și prevederilor legislației în vigoare cu privire la protejarea datelor cu caracter personal.
        Cookie-uri pentru geotargetting
        Aceste Cookie-uri sunt utilizate de către un soft care stabilește din ce țară proveniți. Este complet anonim și este folosit doar pentru a targeta conținutul – chiar și atunci când sunteți pe pagina noastră în limba română sau în altă limbă, primiți aceeași reclamă.
        </p>
        <p className='mt-[.5rem]'>
        Cookie-uri pentru înregistrare
        Atunci când vă înregistrați pe acest site, generăm un Cookie care ne anunța daca sunteți înregistrat sau nu. Serverele noastre folosesc aceste Cookie-uri pentru a ne arăta contul cu care sunteți înregistrat și daca aveți permisiunea pentru un serviciu anume.
        De asemenea, ne permite să asociem orice comentariu pe care îl postați pe site-ul nostru cu username-ul dvs. Dacă nu ați selectat “păstrează-mă înregistrat”, acest Cookie se va șterge automat când veți închide browserul sau calculatorul.
        Cookie-uri pentru publicitate
        Aceste Cookie-uri ne permit să aflăm dacă ați vizualizat sau nu o reclamă online, care este tipul acesteia și ce durată de timp a trecut de când ați văzut mesajul publicitar.
        Aceste Cookie-uri se folosesc și pentru a targeta publicitatea online. Putem folosi, de asemenea, Cookie-uri aparținând unei terțe părți, pentru o mai bună targetare a publicității, pentru a arăta, de exemplu, reclame despre vacanțe, dacă utilizatorul a vizitat recent un articol de pe un site despre vacanțe.
        Aceste Cookie-uri sunt anonime, ele stochează informații despre contentul vizualizat, nu despre utilizatori. De asemenea, setăm Cookie-uri anonime și prin alte site-uri pe care avem publicitate. Primindu-le, astfel, noi le putem folosi pentru a vă recunoaște ca vizitator al acelui site dacă, ulterior, veți vizita site-ul nostru, vă vom putea livra publicitatea bazată pe această informație.Cum sunt folosite Cookie-urile de către acest site?
      </p>
      <h6 className='mt-[.5rem] font-bold'>7. Conțin Cookie-urile date personale?</h6>
      <p className='mt-[1rem]'>
        Cookie-urile în sine nu solicită informații cu caracter personal pentru a putea fi utilizate și, în cele mai multe cazuri, nu identifică personal utilizatorii de internet.
        Datele personale colectate prin utilizarea Cookie-urilor pot fi colectate doar pentru a facilita anumite funcționalități pentru utilizator. Aceste date sunt criptate într-un mod care face imposibil accesul persoanelor neautorizate la ele.
      </p>
      <h6 className='mt-[.5rem] font-bold'>8. Ștergerea Cookie-urilor</h6>
      <p className='mt-[1rem]'>
        În general, o aplicație folosită pentru accesarea paginilor web permite salvarea Cookie-urilor pe terminal în mod implicit. Aceste setări pot fi schimbate în așa fel încât, administrarea automată a Cookie-urilor să fie blocată de browser-ul web sau utilizatorul să fie informat de fiecare dată când Cookie-uri sunt trimise către terminalul său. Informații detaliate despre posibilitățile și modurile de administrare a Cookie-urilor pot fi găsite în zona de setări a aplicației (browser-ului web).
        Limitarea folosirii Cookie-urilor poate afecta anumite funcționalități ale paginii web.
      </p>
      <h6 className='mt-[.5rem] font-bold' >9. Cum pot fi oprite Cookie-urile?</h6>
      <p className='mt-[1rem]'>
      Dezactivarea si refuzul de a primi cookie-uri pot face aceasta pagina de internet dificil de vizitat, atrăgând după sine limitări ale posibilităților de utilizare ale acesteia.

      Utilizatorii își pot configura browserul sa respingă fișierele cookie sau sa fie acceptate cookie-uri de la o pagina de internet anume.

      Toate browserele moderne oferă posibilitatea de a schimba setările cookie-urilor. Aceste setări pot fi accesate, ca regula, în secâiunea “opțiuni” sau in meniul de “preferințe” al browserului tău.

      Totuși, refuzarea sau dezactivarea cookie-urilor nu înseamnă că nu veți mai primi publicitate online – ci doar ca aceasta nu va fi adaptata preferințelor și interesele dvs., evidențiate prin comportamentul de navigare.
      </p>
      <p className='mt-[.5rem]'>
        Pentru a înțelege aceste setări, următoarele linkuri pot fi folositoare:
        Cookie settings in Internet Explorer
        Cookie settings in Firefox
        Cookie settings in Chrome
        Cookie settings in Safari
      </p>
      <p className='mt-[.5rem]'>
        Pentru setările Cookie-urilor generate de terți, puteți consulta și site-ul:
        <a href="http://www.youronlinechoices.com/ro/">http://www.youronlinechoices.com/ro/</a> – unde găsiți mai multe informații privind confidențialitatea legată de publicitatea online.
      </p>
      <p className='mt-[.5rem]'>
         Pentru orice întrebări suplimentare cu privire la modul in sunt utilizate cookie-urile prin intermediul acestei pagini de internet, precum si pentru alte solicitări referitoare la protecția datelor dumneavoastră cu caracter personale, va rugam sa va adresați către: <span className='font-bold'>info@waygital.ro </span> ,<a href="tel:+40-753-616-640  "> (0753) 616 640</a> .
      </p>
</div>
    </div>
  )
}

export default PoliticaCookie