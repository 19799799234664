import React from 'react'

const PoliticaDeConfidentialitate = () => {
  return (
    <div className='flex flex-col items-center   bg-white py-[10rem]'>
    <div className='w-[80%] lg:w-[50rem]'>
        <h6 className='text-[28px] font-bold'>Politica de confidențialitate</h6>
          <p className='mt-[1rem]'>
            WAYGITAL VISIONARY SRL garantează securitatea și confidențialitatea datelor găzduite și transmise prin sistemul său informatic. Aceste informații vor putea fi folosite de către WAYGITAL VISIONARY SRL  pentru a trimite utilizatorului confirmarea comenzilor, diverse oferte speciale, promoții, etc. numai în baza consimțământului persoanei vizate.
          </p>
          <p className='mt=[.5rem]'>
            Furnizarea datelor personale către WAYGITAL VISIONARY SRL nu implică obligativitate din partea utilizatorilor, iar aceștia pot refuza furnizarea acestor date în orice circumstanțe și pot solicita în mod gratuit ștergerea acestora din baza de date.
          </p>
          <p className='mt=[.5rem]'>
            Pentru a face posibilă facturarea, expedierea și livrarea comenzilor plasate, trebuie ca utilizatorul să fie de acord ca WAYGITAL VISIONARY SRL să colecteze și să prelucreze datele înscrise, conform cerințelor Legii nr. 679/2016 (GDPR).
          </p>
          <p className='mt=[.5rem]'>
            Conform cerințelor Legii nr. 679/2016 (GDPR) pentru protecția persoanelor cu privire la prelucrarea datelor cu caracter personal, precum și protecția vieții private în sectorul comunicațiilor electronice, WAYGITAL VISIONARY SRL are obligația de a administra, în condiții de siguranță și numai pentru scopurile specificate, datele personale furnizate.
          </p>
          <p className='mt=[.5rem]'>
            În acest sens, WAYGITAL VISIONARY SRL a elaborat o serie de măsuri tehnice și organizatorice pentru prevenirea riscurilor ce pot apărea în cadrul prelucrării datelor cu caracter personal.
          </p>
          <p className='mt=[.5rem]'>
            Prelucrarea datelor cu caracter personal în interiorul organizației este condiționată de o serie de măsuri tehnice și organizatorice în vederea securizării acestora.
          </p>
          <p className='mt=[.5rem]'>
            Aceste măsuri au rolul de a proteja informațiile la nivelul organizației împotriva incidentelor de securitate.
          </p>
            <h6 className='mt-[.5rem] font-bold'> Măsurile tehnice de protecție a datelor: </h6>
          <p className='mt-[1rem]'>
            • Certificatul SSL – are rolul de a securiza schimbul de informații prin Internet. Acesta criptează informațiile, înainte ca acestea să circule prin Internet. Informațiile criptate nu pot fi decriptate, decât de serverul căruia îi sunt adresate. Acest lucru garantează că informațiile trimise către un website/platformă online nu vor fi furate, interceptate, prelucrate.
          </p>
          <p className='mt-[.5rem]'>
            Informațiile despre carduri bancare, parole și în general orice informație care se dorește a rămâne privată este securizată de acest certificat.
          </p>
          <p className='mt-[.5rem]'>
            • Certificatul SSL al platformei online WayGital.ro  este folosit și pentru securizarea corespondenței pe e-mail, în așa manieră încât, datele personale ale clienților să circule într-un mediu securizat și reglementat de o serie de măsuri de securitate care asigură confidențialitatea informațiilor.
          </p>
          <p className='mt-[.5rem]'>
            • Back-up automat– setat la un interval de timp pentru garantarea informațiilor și pentru ca toți clienții să fie siguri că informațiile și preferințele furnizate de ei nu dispar și nu sunt distruse, pierdute sau incorecte în cazul unei erori a serverului.
          </p>
          <p className='mt-[.5rem]'>
            • Filtre anti – spam și antivirus care previn infiltrarea conținutului malițios sau a unor viruși care pot prelucra datele în mod neautorizat sau care le pot transmite către alte entități sau persoane care nu au obținut consimțământul persoanei vizate.
          </p>
          <p className='mt-[.5rem]'>
            • Securizarea modulelor și a scripturilor care comunică în interiorul platformei. Se verifică în mod constant funcționarea elementelor implicate în interacțiunea client-server, server-client.
          </p> 
          <p className='mt-[.5rem]'>
            • Verificarea și optimizarea modulelor în vederea menținerii lor up-to-date pentru prevenirea vulnerabilităților. Aceasta măsură previne identificarea vulnerabilităților, la nivel global, în platformele utilizate, vulnerabilități de tip 0-day ce pot intercepta schimbul de date și implicit date cu caracter personal în interacțiunile clientului cu platforma sau ale responsabilului de proces cu clientul și platforma.
          </p>
          <p className='mt-[.5rem]'>
            • Clasificarea tipurilor de acces de către responsabilul de proces – grupuri de administrare, posibilitatea de a adăuga sau șterge anumite drepturi pe un user cu acces deplin – personalizarea accesului în funcție de necesitate.
            Protejarea cu parolă a dispozitivului de pe care, responsabilul de proces efectuează prelucrările de date, pentru a preveni intervenția neautorizată.
          </p>
          <p className='mt-[.5rem]'>
            • Firewall – program software și componenta hardware instalată în locația serverelor al firmei care oferă hosting-ul platformei online, sunt destinate protejării serverului și al echipamentelor de rețea, împotriva atacurilor informatice, încercării de pătrundere neautorizată, instalării de aplicații software malițioase ce pot pune în pericol datele cu caracter personal ale utilizatorilor platformei. Firewall-ul blochează accesul persoanelor neautorizate la informațiile stocate pe echipamentul conectat la Internet.
          </p>
          <p className='mt-[.5rem]'>
            • Accesul la sistemele de procesare a datelor în care sunt procesate datele personale este posibil doar după ce persoana autorizată a fost identificată și autentificată cu succes (de ex. cu nume de utilizator și parolă sau card cu chip/PIN), cu utilizarea măsurilor de securitate cele mai performante. În cazul lipsei autorizației, accesul este refuzat.
          </p>
          <p className='mt-[.5rem]'>
            • Toate încercările de accesare, atât cele reușite, cât și cele respinse, sunt înregistrate (ID de utilizator, computer, adresa IP utilizată) și arhivate într-un format conform regulilor de audit pentru 3 luni. În vederea detectării folosirii necorespunzătoare, serverul efectuează verificări repetate, aleatorii;
          </p>
          <p className='mt-[.5rem]'>
            • Accesul este blocat după încercări de autentificare incorecte repetate.
          </p>
          <p className='mt-[.5rem]'>
            • Verificarea constantă a vulnerabilităților platformei, care ar putea permite extragerea de informații și date cu caracter personal. Hosting-ul are măsuri și soluții de securitate care scanează recurent fișierele procesate și fluxul de date care circulă în interiorul platformei;
          </p>  
          <p className='mt-[.5rem]'>
            • Combaterea riscurilor unor breșe de securitate prin luarea măsurilor de precauție din punct de vedere tehnic și organizatoric prin securizarea platformei și update-ul constant cu versiuni stabile ale acesteia.
          </p>
          
            <h6 className='mt-[.5rem] font-bold'>Măsuri organizatorice de protecție a datelor:</h6>
          <p className='mt-[.5rem]'>
            • Distrugerea documentelor care nu mai sunt necesare (notițe, facturi eronate, etc.) utilizând un distrugător de documente la dispoziția responsabilului de proces;
          </p>
          <p className='mt-[.5rem]'>
            • Eliminarea riscului generat de factorul uman prin interzicerea prelucrării de informații în afara platformei securizate, cu excepția întocmirii notelor de transport în platforma firmei de curierat, care, este de asemenea, un mediu securizat;
          </p>
          <p className='mt-[.5rem]'>
            • Adoptarea măsurilor de securitate fără a face diferențierea între tipurile de clienți (nou/ existent/ potențial);
          </p>
          <p className='mt-[.5rem]'>
            • Adoptarea unei politici interne de verificare a proceselor și al prelucrărilor în momentul punerii în livrare a produsului sau preluării informațiilor cu privire la o comandă sau posibilă ofertă;
          </p>
          <p className='mt-[.5rem]'>
            • Evitarea diferențierii clienților pe baza criteriilor cu caracter personal, precum orientarea sexuală, interesele sexuale, sexul , religia, apartenența la mișcări sau grupări, etc. Clienții sunt liberi să comande și să aleagă ceea ce iși doresc. Prin această măsură, considerăm că respectăm integritatea persoanei și evităm orice urmă de analiză/profilare pe baza acestor criterii.
          </p>
          <p className='mt-[.5rem]'>
            • Actualizarea politicii de confidențialitate WayGital
          </p>  
          <p className='mt-[.5rem]'>
            • Instruirea responsabilului de proces cu privire la necesitatea notificării în cazul unui incident de securitate major.
          </p>
          <p className='mt-[.5rem]'>
            • Instruirea responsabilului de proces cu privire la gestionarea situațiilor ce pot apărea în momentul prelucrării datelor în interiorul platformei (erori, erori de utilizare).
          </p>
          <p className='mt-[.5rem]'>
            • Instruirea responsabilului de proces cu privire la utilizarea informațiilor pe care le prelucrează și conștientizarea asupra caracterului informațiilor personale;
          </p>
          <p className='mt-[.5rem]'>
            • Interzicerea prelucrării datelor în afara platformei prin gestionarea comenzilor direct în interfața de utilizare a platformei, nefiind necesară prelucrarea datelor în alte medii nesecurizate și vulnerabile.
          </p>
          <p className='mt-[.5rem]'>
            • Responsabilul de proces este instruit periodic cu privire la:
          </p>
          <p className='mt-[.5rem]'>
            – Principiile protecției datelor, inclusiv măsurile tehnice și organizatorice
          </p>
          <p className='mt-[.5rem]'>
            – Cerința de a păstra secretul datelor și confidențialitatea cu privire la secretele organizației și secretul comercial, inclusiv tranzacțiile realizate;
          </p>
          <p className='mt-[.5rem]'>
            – Utilizarea corectă, atentă a datelor, mediilor de date și a altor documente;
          </p>
          <p className='mt-[.5rem]'>
            – Secretul telecomunicațiilor;
          </p>
          <p className='mt-[.5rem]'>
            – Alte obligații specifice privind confidențialitatea, acolo unde este necesar;
          </p>
            <h6 className='mt-[.5rem] font-bold'>Prelucrarea datelor </h6>
          <p className='mt-[1rem]'>
            Din punct de vedere al prelucrării, în cadrul WayGital.ro, datele cu caracter personal sunt prelucrate doar pentru scopurile pentru care s-a obținut consimțământul persoanelor vizate, inclusiv pentru scopurile paralele și pentru încheierea unui contract sau livrarea unui produs către client, cerut de acesta.
          </p>
          <p className='mt-[.5rem]'>
            Având în vedere că, această organizație își desfășoară activitatea în cea mai mare parte în mediul online, prelucrarea datelor cu caracter personal ale clienților sunt transmise online prin intermediul aplicațiilor și al platformei pe care se solicită comenzi și cereri de ofertă. Datele colectate sunt minimizate și sunt în legătură directă cu scopul pentru care s-a obținut consimțământ și sunt necesare pentru a contacta clientul în cazul unei cereri de ofertă sau pentru a livra și pune la dispoziție produsul/serviciul comandat conform cerințelor sau returul acestuia.
          </p>
          <p className='mt-[.5rem]'>
            WAYGITAL VISIONARY SRL persoana juridica inregistrata la Registrul Comertului (nr. J10/152/17.02.2023, CUI RO47656533) este operator direct dar si imputernicit. Scopul prelucrării datelor cu caracter personal este furnizarea de produse și servicii prin intermediul platformei online cât și scopurile paralele ale acestei activități: procesarea informațiilor necesare livrării, îmbunătățirea experienței pentru utilizator prin reținerea anumitor setări sau preferințe, după obținerea consimțământului acestuia, modificări de preț, caracteristici ale produselor/serviciilor, modificări ale stocului, promoții, facturare.
          </p>
          <p className='mt-[.5rem]'>
            Categoriile de persoane vizate sunt: clienți actuali / potențiali clienți sau vizitatori ai site-ului web.
          </p>
          <p className='mt-[.5rem]'>
            Modurile în care persoanele vizate sunt informate asupra drepturilor lor sunt:
          </p>
          <p className='mt-[.5rem]'>
            • Politica de confidențialitate;
          </p>
          <p className='mt-[.5rem]'>
            • Pe pagina web într-o secțiune dedicata (cookie notice);
          </p>
          <p className='mt-[.5rem]'>
            Exercitarea drepturilor prevăzute de legea 679 / 2016 (GDPR) revin în totalitate operatorului, care are obligația legală și de a-și desemna o persoană responsabilă de procesarea datelor personale în interiorul organizației. Această persoană va elabora un set de măsuri tehnice și organizatorice pentru securizarea prelucrării datelor și are obligația de a informa operatorul cu privire la natura proceselor de prelucrare, tipuri de informații și modul în care, aceste procese se desfășoară în interiorul organizației. Operatorul are răspunderea și obligația de a se asigura că aceste măsuri sunt implementate, că nu există riscul unor breșe de securitate sau a unor scurgeri de informații, precum și respectarea legislației în vigoare în ceea ce privește prelucrarea datelor și drepturile persoanelor vizate.
            Prin intermediul platformei online sunt prelucrate următoarele date cu caracter personal:
          </p>
          <p className='mt-[.5rem]'>
            • nume și prenume
          </p>
          <p className='mt-[.5rem]'>
            • email
          </p>
          <p className='mt-[.5rem]'>
            • telefon
          </p>
          <p className='mt-[.5rem] font-bold'>
            WAYGITAL VISIONARY SRL nu prelucrează categorii de date cu caracter special.
          </p>
          <p className=' font-bold'>
            WAYGITAL VISIONARY SRL nu efectuează transferul de date în străinătate sau către terți.
          </p>
          <p className='mt-[.5rem]'>
            Prelucrarea datelor cu caracter personal, nu are legătură cu alte sisteme de evidență. Activitatea propriu-zisă a firmei este aceea de a prelua comenzile inițiate de clienți prin intermediul platformei online, de a le stoca și prelucra în vederea facturării, expedierii și furnizării produselor comandate.
          </p>
          <p className='mt-[.5rem]'>
            Prelucrarea informațiilor sunt procesate și stocate strict în concordanță cu scopurile pentru care s-a oferit consimțământul acestuia:
          </p>
          <p className='mt-[.5rem]'>
            • Facturare;
          </p>
          <p className='mt-[.5rem]'>
            • Livrare;
          </p>
          <p className='mt-[.5rem]'>
            • Retragerea dint-un contract încheiat (retragerea se poate face conform legii, ținând cont de condițiile în care acest contract a fost încheiat inițial și de prevederile legale agreate inițial);
          </p>
          <p className='mt-[.5rem]'>
            Scopul colectării datelor este de a factura comenzile, de a trimite corespondența și de a onora comenzile. Refuzul dumneavoastră de a furniza datele, determină imposibilitatea plasării pe acest site a comenzii dvs. și prelucrării ei, conform cerințelor, precum și imposibilitatea îndeplinirii scopului.
          </p>
          <p className='mt-[.5rem]'>
            Conform Legii nr. 679/2016 (GDPR), utilizatorul beneficiază de dreptul de acces, dreptul de a fi uitat, dreptul de a porta informațiile și datele cu caracter personal, dreptul de intervenție asupra datelor, dreptul de a nu fi supus unei decizii individuale și dreptul de a se adresa justiției. Totodată, are dreptul de a se opune prelucrării datelor personale și poate solicita ștergerea datelor. Pentru exercitarea acestor drepturi, utilizatorul se poate adresa cu o cerere scrisă, datată și semnată la adresa de e-mail <span className='font-bold'>info@waygital.ro </span>. De asemenea, dacă unele din datele despre utilizatori sunt incorecte, avem rugămintea să ne fie semnalat acest lucru, pentru a putea efectua corecțiile necesarere. WAYGITAL VISIONARY SRL
          </p>
        </div>
    </div>
  )
}

export default PoliticaDeConfidentialitate